import { Fade, Slide } from "react-reveal";
import { Accordion } from "react-bootstrap";
import "./faq.css";

const content = [
  {
    id: 1,
    title: "How can I download the app?",
    text: "You can download the SKIIPE app on the App Store or google play store. Links are also on the last page of the site.",
  },
  {
    id: 2,
    title: "What is the aim of SKIIPE?",
    text: "At SKIIPE we are trying to make a change supporting small businesses with an environment of resources and customers to enable them grow.",
  },
  {
    id: 3,
    title: "Is the app free to use?",
    text: "The app is free to use. Although some paid feature for merchants to enable them sell better coming soon!.",
  },
  {
    id: 4,
    title: "How can I become a merchant on SKIIPE?",
    text: "When you login to the app. Go to accounts then switch to merchant and proceed!",
  },
];

const FAQ = () => {
  return (
    <section className="col-md-10 col-12">
      <Fade top big>
        <h3 className="primary__heading col-11 col-md-12 mx-auto text-center">
          FAQ’s
          <p className="sub__text">Have any questions we are here to help.</p>
        </h3>
      </Fade>
      <Slide big left>
        <div className="col-md-9 col-11 mx-auto mt-4 mb-5">
          <Accordion defaultActiveKey="0" flush>
            {content.map((c) => (
              <Accordion.Item eventKey={c.id} key={c.id}>
                <Accordion.Header>{c.title}</Accordion.Header>
                <Accordion.Body>{c.text}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </Slide>
      <Slide big right>
        <p className="col-11 col-md-5 text-center mx-auto small__text mt-4">
          Still have questions and you can’t find the answers you’re are looking
          for, Please contact us.
        </p>
      </Slide>
    </section>
  );
};

export default FAQ;
