import { Slide } from "react-reveal";
// import { Button, Input } from "../../components";
// import download from "../../assets/download.svg";
import applestore from "../../assets/applestore.svg";
import playstore from "../../assets/playstore.svg";
import "./download.css";

const Download = () => {
  return (
    <div className="download__wrapper">
      <div className="col-12 d-flex justify-content-center mb-3">
        <Slide top big>
          {/* <div className="col-md-10 col-12 subscribe">
            <div className="row justify-content-center">
              <h3 className="primary__heading col-12 text-center">
                Subscribe
                <p className="sub__text">Subscribe to get updates</p>
              </h3>
              <div className="col-12 col-md-10 d-flex justify-content-center">
                <Input
                  type="email"
                  subscribe={true}
                  placeHolder="Enter Email"
                  inputIcon={
                    <Button type="submit" text="Subscribe" subscribe={true} />
                  }
                />
              </div>
            </div>
          </div> */}
        </Slide>
      </div>
      <section className="container mb-5" id="download">
        <div className="col-12 col-md-10 mx-auto">
          <div className="row justify-content-center m-0">
            <Slide left big>
              <div className="col-md-6 col-12 d-flex justify-content-center">
                <img
                  src="https://res.cloudinary.com/utfrank/image/upload/v1661978366/Skiipe/download.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </Slide>
            <Slide right big>
              <div className="col-md-6 col-12">
                <h3 className="primary__heading col-12">
                  Thank you for choosing <span>SKIIPE</span>
                  <p className="sub__text">
                    We would be thrilled to begin this journey with you.
                  </p>
                </h3>
                <div className="row justify-content-center m-0 mt-5">
                  <div className="col-md-6 col-12 px-3 py-2">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.skiipe.skiipe"
                      target="_blank"
                      className="btn col-12 p-0"
                      rel="noreferrer"
                    >
                      <img src={playstore} alt="" className="img-fluid" />
                    </a>
                  </div>
                  <div className="col-md-6 col-12 px-3 py-2">
                    <a
                      href="https://apps.apple.com/ng/app/skiipe/id1633677017"
                      target="_blank"
                      className="btn col-12 p-0"
                      rel="noreferrer"
                    >
                      <img src={applestore} alt="" className="img-fluid" />
                    </a>
                  </div>
                </div>
              </div>
            </Slide>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Download;
