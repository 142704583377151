import {
  Footer
} from "../../containers";
import image1 from "../../assets/images/image-1.jpeg";
import image2 from "../../assets/images/image-2.jpeg";
import image3 from "../../assets/images/image-3.jpeg";
import image4 from "../../assets/images/image-4.jpeg";

const DeleteAccount = () => {
  return (
    <div className="col-12 mt-5">
      <div className="container mb-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 mt-4">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-6">
                <h1 className="col-12"><strong>Log into the app on play store or App Store</strong></h1>
              </div>
              <div className="col-md-3">
                <img
                  src={image1}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="col-12 mt-4">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-6">
                <h1 className="col-12"><strong>Navigate to the profile page</strong></h1>
              </div>
              <div className="col-md-3">
                <img
                  src={image2}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="col-12 mt-4">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-6">
                <h1 className="col-12"><strong>Scroll down to delete account</strong></h1>
              </div>
              <div className="col-md-3">
                <img
                  src={image3}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="col-12 mt-4">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-6">
                <h1 className="col-12"><strong>Click delete account and Proceed</strong></h1>
              </div>
              <div className="col-md-3">
                <img
                  src={image4}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
        <Footer />
    </div>
  )
}

export default DeleteAccount