import React, { Fragment } from "react";
import "./footer.css";
import { Socials } from "../../components";
import { Link } from "react-router-dom";

const product = ["overview", "features"];
const company = ["about", "contact"];
const legal = ["terms", "privacy"];

const year = new Date();

const Footer = () => {
  return (
    <footer className="container-fluid footer">
      <div className="footer__main">
        <div className="container mx-auto">
          <div className="row justify-content-center m-0">
            <div className="col-md-3 col-6 mb-5 mb-md-3">
              <h5 className="footer__heading col-12">SKIIPE</h5>
              <p className="footer__text col-12">24/7 Support</p>
            </div>
            <div className="col-md-3 col-6 mb-5 mb-md-3">
              <h5 className="footer__heading col-12">Product</h5>
              {product.map((p, i) => (
                <ul className="footer__list" key={i}>
                  <li className="footer__item">
                    <a href={"#" + p} className="footer__link">
                      {p}
                    </a>
                  </li>
                </ul>
              ))}
            </div>
            <div className="col-md-3 col-6 mb-5 mb-md-3">
              <h5 className="footer__heading col-12">Company</h5>
                <ul className="footer__list">
              {company.map((c, i) => (
                  <React.Fragment key={i}>
                    <li className="footer__item">
                      <a href={"#" + c} className="footer__link">
                        {c}
                      </a>
                    </li>
                  </React.Fragment>
              ))}
              <li className="footer__item">
                <Link to="/delete_account" className="footer__link">
                  Delete accont
                </Link>
              </li>
                </ul>
            </div>
            <div className="col-md-3 col-6 mb-5 mb-md-3">
              <h5 className="footer__heading col-12">Legal</h5>
              {legal.map((r, i) => (
                <ul className="footer__list" key={i}>
                  <li className="footer__item">
                    <Link to={r} className="footer__link">
                      {r}
                    </Link>
                  </li>
                </ul>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <div className="container">
          <div className="row m-0">
            <div className="col-md-6 col-6 my-2">
              <p className="para m-0 text-start text-white">
                &copy;Skiipe {year.getFullYear()}. All copyrights reserved
              </p>
            </div>
            <div className="col-md-6 col-6 my-2">
              <div className="row justify-content-end m-0">
                <Socials white={true} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
