import { Fade, Slide } from "react-reveal";
import "./hiw.css";
import shoppingCart from "../../assets/shopping-cart.svg";
import search from "../../assets/search.svg";
import messages from "../../assets/messages.svg";
import qrcode from "../../assets/qr-code.svg";

const content = [
  {
    id: 1,
    imgUrl: shoppingCart,
    title: "Become a Merchant",
    text: "Sign up, create your virtual store quick and easy, sort by category, upload your product and viola you’re set.",
  },
  {
    id: 2,
    imgUrl: search,
    title: "Search for Stores",
    text: "Search for any online store, product or service on our platform. Use location filters to connect to merchants or products near you instantly.",
  },
  {
    id: 3,
    imgUrl: messages,
    title: "Chat with a Merchant",
    text: "See a product you like chat up the merchant, leave store ratings, and share the products with your friends,cause fun things are better shared.",
  },
  {
    id: 4,
    imgUrl: qrcode,
    title: "QR Code",
    text: "QR codes quick, fast and easy unique address for your buisness open, scan and shop.",
  },
];
const HIW = () => {
  return (
    <section className="col-md-10 col-12" id="hiw">
      <div className="row justify-content-center">
        <Fade bottom>
          <h3 className="primary__heading col-12 text-center">
            How It <span>Works</span>
            <p className="sub__text">A mobile platform where users can</p>
          </h3>
        </Fade>
        <div className="col-12 mt-5">
          <div className="row justify-content-center align-items-center">
            {content.map((c) => (
              <div className="col-md-6 col-12 mb-4 mb-md-5" key={c.id}>
                <Slide cascade left>
                  <div className="col-12 hiw__card">
                    <div className="col-1 mx-auto d-flex justify-content-center mb-0">
                      <img src={c.imgUrl} alt="" className="img-fluid" />
                    </div>
                    <h5 className="col-12 text-center hiw__card-title">
                      {c.title}
                    </h5>
                    <p className="col-9 para text-center mx-auto hiw__card-desc">
                      {c.text}
                    </p>
                  </div>
                </Slide>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HIW;
