import { Flip } from "react-reveal";
import { Container, Navbar, Nav } from "react-bootstrap";
import { Button } from "../../components";
import "./navigation.css";
import logo from "../../assets/logo.svg";
import hamburger from "../../assets/hamburger.svg";
import { Outlet, Link } from "react-router-dom";

const Navigation = () => {
  return (
    <>
      <Flip big bottom>
        <Navbar expand="lg">
          <Container>
            <Navbar.Brand>
              <Link to="/">
                <img src={logo} alt="skiipe logo" className="img-fluid" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <img src={hamburger} alt="skiipe logo" className="img-fluid" />
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mx-auto">
                <Link className="nav-link" to="/#about">About Us</Link>
                <Link className="nav-link" to="/#hiw">How It Works</Link>
                <Link className="nav-link" to="/#contact">Contact Us</Link>
                <Link className="nav-link" to="terms">Terms</Link>
                <Link className="nav-link" to="delete_account">Delete Account</Link>
              </Nav>
              <Nav className="ms-auto d-none d-lg-flex">
                <a className="primary-button" href="#download">
                  Get Started
                </a>
                {/* <Nav.Link href="#link">Download</Nav.Link> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Flip>
      <Outlet />
    </>
  );
};

export default Navigation;
