import { Button } from "../../components";
import "./features.css";
import features from "../../assets/features.svg";
import { Slide } from "react-reveal";

const Features = () => {
  return (
    <section className="col-md-10 col-12" id="features">
      <div className="row justify-content-center">
        <Slide right big>
          <div className="col-md-6 col-12">
            <img src={features} alt="" className="img-fluid features__img" />
          </div>
        </Slide>
        <Slide left big>
          <div className="col-md-6 col-12">
            <h3 className="primary__heading col-md-12 col-11 mx-auto">
              Why choose <br />
              <span>SKIIPE</span>
            </h3>

            <div className="col-md-12 col-11 mx-auto mb-4 p-0">
              <div className="row justify-content-center align-items-center m-0">
                <p className="col-12 features__text mb-0">
                  Fast and easy <br />
                  access to <br />
                  your desired <br />
                  product.
                </p>
              </div>
            </div>

            <div className="col-11 col-md-12 mx-auto">
              <a className="primary-button" href="#download">
                Get Started
              </a>
            </div>
          </div>
        </Slide>
      </div>
    </section>
  );
};

export default Features;
