import "./socials.css";
import grayLinkedin from "../../assets/gray-linkedin.svg";
import grayMessenger from "../../assets/gray-messenger.svg";
import grayTwitter from "../../assets/gray-twitter.svg";
import grayTwoo from "../../assets/gray-twoo.svg";
import whiteLinkedin from "../../assets/white-linkedin.svg";
import whiteMessenger from "../../assets/white-messenger.svg";
import whiteTwitter from "../../assets/white-twitter.svg";
import whiteTwoo from "../../assets/white-twoo.svg";

const links = [
  "https://www.linkedin.com/company/skiipe/",
  "facebook",
  "https://twitter.com/skiipeafrica?s=11&t=o3WkpReYavMDAf2B1dsQ5g",
  "twillow",
];
const grayImg = [grayLinkedin, grayMessenger, grayTwitter, grayTwoo];
const whiteImg = [whiteLinkedin, whiteMessenger, whiteTwitter, whiteTwoo];

const Socials = ({ white, black }) => {
  return (
    <ul className="social__list">
      {white &&
        links.map((link, index) => (
          <li className="social__item" key={index}>
            <a
              className="social__link"
              href={link}
              target="_blank"
              rel="noreferrer"
            >
              <img src={whiteImg[index]} alt="" />
            </a>
          </li>
        ))}
      {black &&
        links.map((link, index) => (
          <li className="social__item" key={index}>
            <a
              className="social__link"
              href={link}
              target="_blank"
              rel="noreferrer"
            >
              <img src={grayImg[index]} alt="" />
            </a>
          </li>
        ))}
    </ul>
  );
};

export default Socials;
