import {
  About,
  Contact,
  Download,
  FAQ,
  Features,
  Footer,
  Header,
  HIW,
} from "../../containers";

const Home = () => {
  return (
    <main className="container-fluid p-0">
      <div className="row justify-content-center m-0">
        <Header />
        <HIW />
        <About />
        <Features />
        <FAQ />
        <Contact />
        <Download />
        <Footer />
      </div>
    </main>
  )
}

export default Home