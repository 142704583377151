import "./legal.css";

const Terms = () => {
  return (
    <div className="container mb-5">
      <h1 className="text-center mt-5 terms_h">Terms of use</h1>
      <p className="col-12">
        Welcome to Skiipe. Skiipe is a marketplace where you can create your
        product-based store and sell your products directly to African buyers.
        We want to ensure buyers and sellers have a positive experience on
        skiipe. Please read on to learn more about your rights and what is
        expected of you within the application. By selling on skiipe, you agree
        to these terms of use.
      </p>
      <ol className="mb-5">
        <li>
          <h4 className="terms_sh">
            Understanding skiipe’s limitations as a marketplace
          </h4>
        </li>
        <li>
          <h4 className="terms_sh">Basics for sellers</h4>
          <ol type="i">
            <li>what can be sold on skiipe</li>
            <li>what can't be sold on skiipe</li>
            <li>managing your store on skiipe</li>
          </ol>
        </li>
        <li>
          <h4 className="terms_sh">Being a user and member of the community</h4>
          <ol type="i">
            <li>Uploading content</li>
            <li>Messaging</li>
          </ol>
        </li>
      </ol>
      <ol>
        <li>
          <h4 className="terms_sh">
            Understanding skiipe’s limitations as a marketplace
          </h4>
          <p>
            skiipe is a marketplace for buyers to discover and purchase from
            African sellers. It is important to note that skiipe is not a part
            of that transaction. By shopping on skiipe, you understand that:
          </p>
          <ol>
            <li>
              You are not buying directly from skiipe but from one of the many
              individual sellers on skiipe;
            </li>
            <li>
              skiipe does not pre-screen items sold on the platform and
              therefore does not guarantee or endorse any items sold on skiipe
              or any content posted by sellers (such as photographs or
              descriptions of listings);
            </li>
            <li>
              Each seller on skiipe has their way of processing an order or
              request.
            </li>
          </ol>
        </li>

        <li>
          <h4 className="terms_sh">Basics for sellers</h4>
          <ol type="i">
            <li>
              what can be sold on skiipe
              <br />
              skiipe is a marketplace where buyers find products they will not
              only find on some e-commerce platforms but buy from individual
              stores. Sellers on skiipe can sell any product they have been
              authorized or are legal to sell, and the products are under the
              given categories on skiipe. <br />
              By selling on skiipe, you agree that.
              <br />
              <ol>
                <li>
                  You have been authorized to sell that product under any law
                  governing your location.
                </li>
                <li>
                  You're the owner of the products you sell, not dropshipping or
                  a drop shipper.{" "}
                </li>
                <li>
                  All listings are available for the set price on your store
                </li>
                <li>
                  You're using your photographs, video content, or renderings
                  that you have been authorized to use and are owned by you. Not
                  pictures of products used by other sellers on skiipe or other
                  e-commerce platforms.
                </li>
                <li>
                  You accurately describe the product according to how it is in
                  the description, including sizes, colors, and any other
                  relevant information that the buyer must know.
                </li>
              </ol>
            </li>
            <li>
              we have zero tolerance for sellers selling prohibited items on
              skiipe, and we have the right to take punishable measures against
              sellers who default on our rules. The following items cannot be
              sold on skiipe
              <br />
              <ol>
                <li>
                  Any item prohibited under any law in your country or location
                </li>
                <li>Nudity and Mature Content</li>
                <li>Tobacco, Illegal Drugs, and Medical Drugs</li>
                <li>Animal Products and Human Remains</li>
                <li>Hazardous Materials, Recalled Items, and Weapons</li>
                <li>Items that Promote, Support, or Glorify Hatred</li>
                <li>
                  Illegal Items, Items Promoting Illegal Activity, and Highly
                  Regulated Items
                </li>
                <li>Items that Promote, Support, or Glorify Violence.</li>
              </ol>
            </li>
            <li>
              managing your store on skiipe
              <br />
              Your store represents you and your business to the skiipe users.
              It’s important that you, your items, and your store are honestly
              and accurately represented.
              <br />
              By selling on skiipe, you agree that you will
              <br />
              <ol>
                <li>
                  Provide honest, accurate information about your store in your
                  store description.
                </li>
                <li>
                  Accurately represent your items with the photos and their
                  description.
                </li>
                <li>Not creating duplicate stores </li>
                <li>Respond to Messages from shoppers on time.</li>
                <li>
                  Resolve disagreements or order issues directly with the buyer.
                  In the unlikely event that you can’t resolve, you can reach
                  out to us.
                </li>
                <li>
                  If you cannot complete an order, you must notify the buyer and
                  cancel the order.
                </li>
              </ol>
            </li>
          </ol>
        </li>

        <li>
          <h4 className="terms_sh">Being a user and member of the community</h4>
          <ol type="i">
            <li>
              uploading content. <br />
              <br />
              We want skiipe to always be an authentic and safe place for
              e-commerce, Help us foster this community. Respect everyone on
              skiipe, don’t spam people or post nudity. As a member of skiipe,
              you have the opportunity to create and upload a variety of
              content, like listings, photos, and videos. In order to keep our
              community safe and respectful, you agree that you will not upload
              content that is:
              <ol>
                <li>Is false, deceptive, or misleading to users</li>
                <li>Doesn't follow the law</li>
                <li>
                  Contains personal or private information, whether is yours or
                  for someone else.
                </li>
                <li>Violates any of the rules described in our policy.</li>
                <li>Contains threats, harassment, and extortion.</li>
              </ol>
            </li>
            <li>
              messaging
              <br />
              <br />
              Messaging involves both seller and buyer interaction to provide
              inquiries or purchase a particular product or products.
              <br />
              <br />
              While messaging on skiipe, you agree to <br />
              <br />
              <ol>
                <li>
                  Not sending any personal details to any seller or buyer you do
                  not trust or details that can be used to harm you.
                </li>
                <li>
                  Stating very clear details to the buyer or seller on any
                  inquiry or agreement you're making with them.
                </li>
                <li>
                  Not spreading hate or saying abusive statements to a buyer or
                  seller. As a member of the community, you have the opportunity
                  to report an item or a store that violates any of skiipe’s
                  policies. reporting is confidential.
                </li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

export default Terms;
