import { Flip } from "react-reveal";
// import { Form } from "react-bootstrap";
// import { Button, Input, Socials } from "../../components";
import { Socials } from "../../components";
// import { AvatarIcon, MessageIcon } from "../../icons";

import "./contact.css";
import avatar from "../../assets/avatar.svg";
import messageWhite from "../../assets/message-white.svg";
import locationWhite from "../../assets/location-white.svg";

const Contact = () => {
  return (
    <Flip bottom big>
      <section
        className="container-fluid d-flex justify-content-center"
        id="contact"
      >
        <div className="col-md-10 col-12">
          <div className="row justify-content-center m-0">
            <div className="col-11 mb-5 mb-md-0">
              <h3 className="primary__heading col-12 text-center">
                Contact <span>Us</span>
                <p className="sub__text">We would love to hear from you</p>
              </h3>
              <div className="col-12 mt-4 mb-5">
                <div className="row justify-content-center">
                  <div className="col-md-6 col-12 d-flex align-items-center para">
                    <div className="col-1">
                      <img src={avatar} alt="" className="img-fluid" />
                    </div>
                    <div className="col-11">
                      <a
                        href="tel:+2348134425731"
                        className="no__underline"
                        style={{ color: "inherit" }}
                      >
                        +234 813 442 5731
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 d-flex align-items-center para">
                    <div className="col-1">
                      <img src={messageWhite} alt="" className="img-fluid" />
                    </div>
                    <div className="col-11">
                      <a
                        href="mailto:Hello@skiipe.com"
                        className="no__underline"
                        style={{ color: "inherit" }}
                      >
                        Hello@skiipe.com
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 d-flex align-items-center para">
                    <div className="col-1">
                      <img src={locationWhite} alt="" className="img-fluid" />
                    </div>
                    <div className="col-11">
                      GRA Phase 2 Port-Harcourt, Nigeria.
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <Socials black={true} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              {/* <div className="col-12 contact__form-wrapper"> */}
              {/* <Form className="contact__form col-12">
                  <Form.Group
                    className="mb-3 contact__form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="contact__form-label">
                      Full Name
                    </Form.Label>
                    <Input
                      type="text"
                      inputIcon={<AvatarIcon />}
                      placeHolder="Jane Doe"
                      input={true}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3 contact__form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="contact__form-label">
                      Email
                    </Form.Label>
                    <Input
                      type="email"
                      inputIcon={<MessageIcon />}
                      placeHolder="janedoe@email.com"
                      input={true}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3 contact__form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="contact__form-label">
                      Message
                    </Form.Label>
                    <Input textarea={true} placeHolder="Message" />
                  </Form.Group>
                  <div className="col-12 mt-5 d-flex justify-content-end">
                    <Button type="submit" text="Send Message" contact={true} />
                  </div>
                </Form> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </section>
    </Flip>
  );
};

export default Contact;
