import { Fade, Slide } from "react-reveal";
// import { Button } from "../../components";

import "./banner.css";
// import banner from "../../assets/banner.svg";

const Banner = () => {
  return (
    <Fade bottom cascade delay={500}>
      <div className="banner col-12">
        <div className="banner__header col-12 text-center">
          <Slide left delay={1000}>
            <h1 className="banner__title col-12">
              <span>SKIIPE</span>
              <br />
              Bringing The Market To <span>You</span>
            </h1>
            <p className="banner__text col-12">
              A Social E-commerce app that connects you to merchants across your
              locations.
            </p>
          </Slide>
        </div>
        <div className="banner__img-wrapper col-12 col-md-6 mx-auto">
          <img
            src="https://res.cloudinary.com/utfrank/image/upload/v1661978591/Skiipe/banner.svg"
            alt=""
            className="banner__img img-fluid"
          />
        </div>
        <div className="banner__link-wrapper col-12 d-lg-none d-md-flex">
          <a className="primary-button" href="#download">
            Get Started
          </a>
        </div>
      </div>
    </Fade>
  );
};

export default Banner;
