import { Fade, Slide } from "react-reveal";
import "./about.css";
// import about from "../../assets/about.svg";

const content = [
  {
    id: 1,
    title: "Our App",
    desc: "Skiipe is a 100% interactive mobile platform enabling users to register their business online, find stores and businesses in their area and also discover and share findings and experiences with friends. We’re also a group of people working to make a difference for small buisnesses in Africa.",
  },
  {
    id: 2,
    title: "Our Mission",
    desc: "Here at SKIIPE we are dedicated to creating unlimited possibilities for your buisness, especially small buisnesses to enable you get access, exposure and the thrilling benefits of a thriving biness at very minimal cost.",
  },
  {
    id: 3,
    title: "Our Team",
    desc: "At SKIIPE, are tenancious fore-front cheerleaders of your buisness and we work hard to build the future we envision. To build one big family.",
  },
];

const About = () => {
  return (
    <section className="col-md-10 col-12" id="about">
      <div className="row justify-content-center">
        <div className="col-md-6 col-12">
          <Fade top>
            <h3 className="primary__heading col-11 col-md-12 mx-auto mb-5">
              About <span>Us</span>
              <p className="sub__text">
                A Little about <span>SKIIPE</span> and Us.
              </p>
            </h3>
          </Fade>
          <Slide left cascade>
            {content.map((c) => (
              <div className="about__card col-11 col-md-12 mx-auto" key={c.id}>
                <h6 className="about__card-title">{c.title}</h6>
                <div className="about__card-text">{c.desc}</div>
              </div>
            ))}
          </Slide>
        </div>
        <div className="col-md-6 col-12 mt-md-0 mt-5">
          <Slide right>
            <img
              src="https://res.cloudinary.com/utfrank/image/upload/v1661978530/Skiipe/about.svg"
              alt=""
              className="img-fluid"
            />
          </Slide>
        </div>
      </div>
    </section>
  );
};

export default About;
