import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import Terms from "./Pages/Legal/terms";
import Privacy from "./Pages/Legal/privacy";
import { Navigation } from "./components";
import DeleteAccount from "./Pages/DeleteAccount/DeleteAccount";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigation />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: "/terms",
        element: <Terms />
      },
      {
        path: "/privacy",
        element: <Privacy />
      },
      {
        path: "/delete_account",
        element: <DeleteAccount />
      },
    ]
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
